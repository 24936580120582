<template>
  <div class="container">
    <section class="hero is-black is-large has-bg-img13 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(605.000000,60.000000) rotate(180) scale(6.500,-6.5000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#FFFFFF" stroke-width="0.16" stroke-miterlimit="1"
          d="M16.8,54l2.6,2.6l-5.1,5.1l-2.6-2.6c-15.6-15.6-15.6-41,0-56.6L14.3,0l5.1,5.1l-2.6,2.6C4.1,20.5,4.1,41.2,16.8,54L16.8,54z
 M68.3,2.6L65.7,0l-5.1,5.1l2.6,2.6c12.8,12.8,12.8,33.5,0,46.3l-2.6,2.6l5.1,5.1l2.6-2.6C83.9,43.5,83.9,18.2,68.3,2.6L68.3,2.6z
 M59.7,11.1l-2.6-2.6L52,13.7l2.6,2.6c3.9,3.9,6,9.1,6,14.6s-2.1,10.7-6,14.6L52,48l5.1,5.1l2.6-2.6c5.3-5.3,8.2-12.3,8.2-19.7
S65,16.4,59.7,11.1L59.7,11.1z M28,13.7l-5.1-5.1l-2.6,2.6c-5.3,5.3-8.2,12.3-8.2,19.7s2.9,14.4,8.2,19.7l2.6,2.6L28,48l-2.6-2.6
c-3.9-3.9-6-9.1-6-14.6s2.1-10.7,6-14.6L28,13.7L28,13.7z M50.9,30.9c0,6-4.9,10.9-10.9,10.9s-10.9-4.9-10.9-10.9S34,19.9,40,19.9
C46,19.9,50.9,24.8,50.9,30.9z M43.6,30.9c0-2-1.6-3.6-3.6-3.6s-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6S43.6,32.9,43.6,30.9z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/broadcast2.svg" alt="TheCompagnie | FPV | DRONE | MEDIA" style="opacity:0.2; max-height:8em;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">Live FPV Broadcast</h1>
          <h2 class="subtitle">The heart of live action</h2>
        </div>
      </div>
    </section>

    <!-- Section: Introduction -->
    <div class="container" style="padding-top: 4em;">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">Innovative Technology for Optimal Immersion</h3>
          <p>
            Discover our <b>live broadcast solution</b> using FPV drones. Our <b>unique system</b> provides dynamic and captivating real-time footage. Perfect for <b>extreme sports, motorsports, concerts, festivals</b>, our technology guarantees <b>total immersion</b> in your events.
          </p>
          <br>
          <p>
            With our <b>gyrocam stabilization system</b>, we ensure an <b>exceptional image quality</b> while maintaining <b>low latency</b>, even in complex environments. Enjoy a <b>live broadcast</b> that captures your audience's attention and transforms each event into a <b>memorable experience</b>.
          </p>
          <hr>
        </div>

        <!-- Section: Stabilization Comparison -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">High-Definition Stabilized Broadcast with Gyrocam</h3>
          <p>
            Traditional FPV drone broadcast systems face major limitations: image quality, excessive weight incompatible with small drones, stabilization causing latency, or even legal constraints.
            <br><br>
            Our solution overcomes these challenges by providing advanced <b>gyroscopic stabilization</b>, also known as <b>gyrocam</b>, delivering <b>high image quality</b> with <b>minimal latency</b>. This lightweight, compact technology allows for <b>dynamic and immersive shots</b>, ideal for live events such as extreme sports, concerts, or festivals.
          </p>
          <br>
          <div class="columns is-multiline" style="margin-top: 2em;">

          <!-- Column for second comparison slider -->
          <div class="column is-6">
            <div class="video-container" id="comparisonContainer2">
              <!-- Bottom video (SD - Classic FPV Broadcast) -->
              <video id="videoBottom2" src="/demo/compareSD2_1.mp4" autoplay muted loop playsinline preload="auto"></video>

              <!-- Top video (HD - Our Broadcast) -->
              <div class="video-top" id="videoTop2">
                <video src="/demo/compareHD2_1.mp4" autoplay muted loop playsinline preload="auto"></video>
              </div>

              <!-- The slider for comparison -->
              <div class="slider" id="slider2">
                <div class="slider-icon" id="sliderIcon2">
                  <img src="@/assets/slider.svg" alt="Slider Icon for Video Comparison">
                </div>
              </div>

              <!-- Labels -->
              <div class="video-label video-label-left">Our FPV Broadcast</div>
              <div class="video-label video-label-right">Classic FPV Broadcast</div>
            </div>
          </div>
          <!-- Column for first comparison slider -->
          <div class="column is-6">
            <div class="video-container" id="comparisonContainer">
              <!-- Bottom video (SD - Classic FPV Broadcast) -->
              <video id="videoBottom" src="/demo/compareSD_1.mp4" autoplay muted loop playsinline preload="auto"></video>

              <!-- Top video (HD - Our Broadcast) -->
              <div class="video-top" id="videoTop">
                <video src="/demo/compareHD_1.mp4" autoplay muted loop playsinline preload="auto"></video>
              </div>

              <!-- The slider for comparison -->
              <div class="slider" id="slider">
                <div class="slider-icon" id="sliderIcon">
                  <img src="@/assets/slider.svg" alt="Slider Icon for Video Comparison">
                </div>
              </div>

              <!-- Labels -->
              <div class="video-label video-label-left">Our FPV Broadcast</div>
              <div class="video-label video-label-right">Classic FPV Broadcast</div>
            </div>
          </div>
        </div>

          <p><small>Images captured and broadcast live with our FPV drones.</small></p>
          <hr>
        </div>

        <!-- Section: Technical Characteristics and Flexibility -->
        <div class="column is-12" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle has-text-left">Technical Characteristics</h3>
          <div class="columns is-multiline">

            <!-- Resolution 1080p / 1080i at 25 or 50 fps -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/resolution.svg" alt="1080p/i Resolution" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Live broadcast in 1080p or 1080i</b><br>Flexible streaming in <b>1080p (progressive) or 1080i (interlaced)</b> with a choice of <b>25 to 50 frames per second</b>. This allows you to adjust broadcast quality to match event needs.
                  </p>
                </div>
              </div>
            </div>

            <!-- Focal Length 75° and Wide Angle 100° -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/focallength.svg" alt="Focal Length" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Focal Length Options</b><br>Choose between <b>24mm focal length</b>, <b>15mm wide-angle equivalent</b>, or even anamorphic for wider views, close-ups, or a cinematic look depending on production needs.
                  </p>
                </div>
              </div>
            </div>

            <!-- 3-Axis Stabilization -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/stabilized.svg" alt="Stabilization" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>3-Axis Stabilization</b><br>Vibration-free imagery with gyrocam technology, retaining the <b>FPV effect</b>, and full stabilization for <b>"cable cam"</b> effects. Dual-operator option allows precise framing control, providing a unique production solution.
                  </p>
                </div>
              </div>
            </div>

            <!-- Adaptability to Different Drone Types -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/adaptivity.svg" alt="Adaptability" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Adaptability</b><br>Adaptable to a wide variety of drones, from traditional FPV drones to smaller models for <b>confined spaces</b>, and even high-speed drones to capture action scenes at high speed.
                  </p>
                </div>
              </div>
            </div>

            <!-- Low Latency -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/latency.svg" alt="Low Latency" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Low Latency</b><br>Our technology guarantees <b>low latency</b>, ideal for live events. Your audience will experience <b>real-time action</b> with perfect synchronization to the actual event.
                  </p>
                </div>
              </div>
            </div>

            <!-- Long Range Transmission -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/broadcast.svg" alt="Transmission" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Long-Range Transmission</b><br>Even in complex environments, our system ensures <b>reliable transmission</b> up to 7 km. Whether in urban areas or vast spaces, the <b>video feed remains stable and high-quality</b>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr>
        </div>

        <!-- Section: Versatility -->
        <div class="column is-12 has-text-left" style="padding: 3em;">
          <h3 class="subtitle has-text-weight-bold">Versatility of our system</h3>
          <p class="has-text-justified">
            Discover the <b>unique versatility</b> of our FPV system: 
            it captures <b>immersive wide views</b>, <b>close-up shots at the heart of the action</b>, all with exceptional fluidity in a single take. Here's a demonstration of our capabilities.
          </p>
          
          <!-- Comparison: Wide and Close-Up Shots -->
          <div class="columns is-multiline is-centered" style="margin-top: 2em;">
            <!-- Wide Shot Video -->
            <div class="column is-6">
              <div class="video-container" style="max-height: 300px;">
                <video src="/demo/wide_shot.mp4" autoplay muted loop playsinline preload="auto"></video>
                <div class="video-label" style="position: absolute; bottom: 10px; right: 20px; background: rgba(0, 0, 0, 0.6); color: white; padding: 5px 10px; border-radius: 5px;">
                  Wide shots & landscapes
                </div>
              </div>
            </div>
            
            <!-- Close-Up Shot Video -->
            <div class="column is-6">
              <div class="video-container" style="max-height: 300px;">
                <video src="/demo/close_shot.mp4" autoplay muted loop playsinline preload="auto"></video>
                <div class="video-label" style="position: absolute; bottom: 10px; right: 20px; background: rgba(0, 0, 0, 0.6); color: white; padding: 5px 10px; border-radius: 5px;">
                  Close-up shots at the heart of the action
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Section: 4K Recording at 100 fps -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em;  margin-top: 1em;">
          <h3 class="subtitle">Simultaneous Recording for Post-Production</h3>
          <p class="has-text-justified" style="margin-bottom: 1em;">
            Our system not only provides <b>live 1080 broadcast</b>. It also allows you to capture every moment in <b>4K up to 100 frames per second</b>, for smooth, detailed slow-motion. Ideal for event recaps or <b>post-production use</b>.
          </p>
          <div class="video-container" id="slowmoContainer">
            <!-- Video for slow motion comparison -->
            <video id="demoSlowmo" src="/demo/demoSlowmo_1.mp4" autoplay muted loop playsinline preload="auto"></video>

            <!-- Labels -->
            <div class="video-label video-label-left">1x speed live</div>
            <div class="video-label video-label-right">0.25x speed post-production</div>
          </div>
          <hr>
        </div>

        <!-- Section: Flexibility and Technical Mastery -->
        <div class="column is-12 has-text-left" style="padding: 1em 3em; margin-bottom: 3em;">
          <h3 class="subtitle has-text-weight-bold">Flexibility</h3>
          <p>
            Our expert FPV drone pilots ensure <b>safety</b> and <b>legal compliance</b> at your events, whether in urban areas, large festival stages, or complex natural environments. We adapt our approach to each situation while strictly adhering to regulations.
          </p>
          <br>
          <p>
            Our technology is designed to offer total flexibility. Whether in confined spaces, small stages, or vast areas like mountains, forests, or above water, our drones guarantee <b>optimal stability</b>, even in windy conditions, with radio interference, or at high altitudes. You’ll get <b>flawless image quality</b> in any environment.
          </p>

          <div class="columns is-centered" style="margin-top: 2em;">
            <div class="column is-8" style="text-align: center;">
              <div class="video-container" style="max-height: 300px; max-width: 100%; margin: 0 auto;">
                <video src="/demo/one_shot.mp4" autoplay muted loop playsinline preload="auto"></video>
                <div class="video-label" style="position: absolute; bottom: 10px; right: 20px; background: rgba(0, 0, 0, 0.6); color: white; padding: 5px 10px; border-radius: 5px;">
                  Flexibility in complex environments
                </div>
              </div>
            </div>
          </div>


          <!-- Icons for environments -->
          <div class="icons-environments" style="margin-top: 2em;">
            <div class="columns is-multiline is-centered is-mobile">
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/mountain.svg" alt="Mountain" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">High Altitude</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/city.svg" alt="Urban" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Urban Environment</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/stages.svg" alt="Concert" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Concerts & Festivals</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/interferences.svg" alt="Radio Interference" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">High-Interference Environment</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/wind.svg" alt="Strong Winds" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Strong Wind</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/forest.svg" alt="Small Spaces" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Small, Complex Spaces</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/water.svg" alt="Over Water" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Marine Environment</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Call to Action Section -->
        <div class="column is-12 has-text-centered">
          <h4 class="subtitle">
            Schedule a test session to experience it firsthand and transform your events:
          </h4>
          <br>
          <button class="button is-black is-outlined is-medium" @click="mailtocontact">
            <span class="icon is-medium">
              <i class="fa fa-envelope"></i>
            </span>
            <span>Contact Us</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Live',
  methods: {
    mailtocontact() {
      window.location.href = "mailto:contact@thecompagnie.eu";
    },
    initSlider(containerId, videoTopId, sliderId) {
      const videoTop = document.getElementById(videoTopId);
      const slider = document.getElementById(sliderId);
      const comparisonContainer = document.getElementById(containerId);
      const labelLeft = comparisonContainer.querySelector('.video-label-left');
      const labelRight = comparisonContainer.querySelector('.video-label-right');
      let isDragging = false;

      // Function to update the slider position
      const updateSliderPosition = (x, rect, width) => {
        if (x < 0) x = 0;
        if (x > width) x = width;

        // Update the clip-path of the top video
        videoTop.style.clipPath = `inset(0px ${width - x}px 0px 0px)`;
        slider.style.left = x + 'px';

        // Update the opacity of labels based on slider position
        const threshold = 0.75 * width; // 75% of the width

        if (x < threshold) {
          // Reduce opacity of the left label as slider moves to the left beyond 75%
          const opacityLeft = Math.min(1, (x / threshold));
          labelLeft.style.opacity = opacityLeft;
        } else {
          labelLeft.style.opacity = 1; // Reset to full opacity if under threshold
        }

        if (x > (width - threshold)) {
          // Reduce opacity of the right label as slider moves to the right beyond 75%
          const opacityRight = Math.min(1, ((width - x) / threshold));
          labelRight.style.opacity = opacityRight;
        } else {
          labelRight.style.opacity = 1; // Reset to full opacity if under threshold
        }
      };

      // Set initial slider position (60% of the width)
      const setInitialSlider = () => {
        const rect = comparisonContainer.getBoundingClientRect();
        const width = rect.width;
        updateSliderPosition(0.60 * width, rect, width);
      };

      setInitialSlider();

      // Mouse and touch events for dragging
      const onMouseDown = () => { isDragging = true; };
      const onMouseUp = () => { isDragging = false; };

      const onMouseMove = (e) => {
        if (!isDragging) return;
        const rect = comparisonContainer.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const width = rect.width;
        updateSliderPosition(x, rect, width);
      };

      const onTouchMove = (e) => {
        if (!isDragging) return;
        const rect = comparisonContainer.getBoundingClientRect();
        const touch = e.touches[0];
        const x = touch.clientX - rect.left;
        const width = rect.width;
        updateSliderPosition(x, rect, width);
      };

      // Event listeners
      slider.addEventListener('mousedown', onMouseDown);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mousemove', onMouseMove);

      slider.addEventListener('touchstart', onMouseDown);
      document.addEventListener('touchend', onMouseUp);
      document.addEventListener('touchmove', onTouchMove);
    }
  },
  mounted() {
    // Initialize sliders
    this.initSlider('comparisonContainer', 'videoTop', 'slider');
    this.initSlider('comparisonContainer2', 'videoTop2', 'slider2');
  }
}

</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  max-width: 100%; /* Ensure the container does not exceed page width */
  height: auto;
  aspect-ratio: 16 / 9; /* Maintain 16:9 ratio for videos */
  margin: 0 auto;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Ensure the video stays behind the top video */
}

/* Upper video styling */
.video-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; /* Make sure the video covers the entire container */
  z-index: 2;
  clip-path: inset(0px 25% 0px 0px); /* Set initial clip to 75% to the right */
}

/* Slider styling */
.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 75%; /* Set slider to start at 75% */
  width: 5px;
  background-color: #fff;
  cursor: ew-resize;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon in the center of the slider */
.slider-icon {
  position: absolute;
  top: 50%;
  width: 2.5em;
  height: 2.5em;
  transform: translateY(-50%);
  cursor: pointer; /* Makes the SVG clickable */
}

.video-label {
  position: absolute;
  font-size: 0.9em !important;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  z-index: 4;
  pointer-events: none; /* Pour que le slider reste cliquable */
  user-select: none; /* Désactive la sélection du texte */
}

.slider-icon img {
  pointer-events: none;
  user-select: none; /* Désactive la sélection du logo du slider */
}

@media screen and (max-width: 768px){
.video-label {
  font-size: 0.5em !important;
}
}

.video-label-left {
  bottom: 10px;
  left: 10px;
}

.video-label-right {
  bottom: 10px;
  right: 10px;
}
</style>
