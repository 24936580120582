<template>
  <div class="container">
    <section class="hero is-black is-large has-bg-img13 pagebg">
      <div class="hero-body">
        <div class="svganim">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="600.000000pt" height="600.000000pt" viewBox="0 0 600.000000 600.000000"
           preserveAspectRatio="xMidYMid meet">
          <metadata>
          Created by potrace 1.11, written by Peter Selinger 2001-2013
          </metadata>
          <g transform="translate(605.000000,60.000000) rotate(180) scale(6.500,-6.5000)"
          fill="#000000" stroke="none">
          <path class="path" fill="transparent" stroke="#FFFFFF" stroke-width="0.16" stroke-miterlimit="1"
          d="M16.8,54l2.6,2.6l-5.1,5.1l-2.6-2.6c-15.6-15.6-15.6-41,0-56.6L14.3,0l5.1,5.1l-2.6,2.6C4.1,20.5,4.1,41.2,16.8,54L16.8,54z
 M68.3,2.6L65.7,0l-5.1,5.1l2.6,2.6c12.8,12.8,12.8,33.5,0,46.3l-2.6,2.6l5.1,5.1l2.6-2.6C83.9,43.5,83.9,18.2,68.3,2.6L68.3,2.6z
 M59.7,11.1l-2.6-2.6L52,13.7l2.6,2.6c3.9,3.9,6,9.1,6,14.6s-2.1,10.7-6,14.6L52,48l5.1,5.1l2.6-2.6c5.3-5.3,8.2-12.3,8.2-19.7
S65,16.4,59.7,11.1L59.7,11.1z M28,13.7l-5.1-5.1l-2.6,2.6c-5.3,5.3-8.2,12.3-8.2,19.7s2.9,14.4,8.2,19.7l2.6,2.6L28,48l-2.6-2.6
c-3.9-3.9-6-9.1-6-14.6s2.1-10.7,6-14.6L28,13.7L28,13.7z M50.9,30.9c0,6-4.9,10.9-10.9,10.9s-10.9-4.9-10.9-10.9S34,19.9,40,19.9
C46,19.9,50.9,24.8,50.9,30.9z M43.6,30.9c0-2-1.6-3.6-3.6-3.6s-3.6,1.6-3.6,3.6s1.6,3.6,3.6,3.6S43.6,32.9,43.6,30.9z"/>
          </g>
          </svg>
        </div>
        <div class="svgnoanim">
          <img src="@/assets/broadcast2.svg" alt="TheCompagnie | FPV | DRONE | MÉDIA" style="opacity:0.2; max-height:8em;">
        </div>
        <div class="container has-text-left">
          <h1 class="title">Transmisión en directo FPV</h1>
          <h2 class="subtitle">El corazón de la acción en directo</h2>
        </div>
      </div>
    </section>

    <!-- Section: Introduction -->
    <div class="container" style="padding-top: 4em;">
      <div class="columns is-multiline">
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">Tecnología innovadora para una inmersión óptima</h3>
          <p>
            Descubre nuestra <b>solución de transmisión en directo</b> con drones FPV. Nuestro sistema <b>único</b> ofrece tomas dinámicas y cautivadoras en tiempo real. Ideal para <b>deportes extremos, deportes de motor, conciertos, festivales</b>, nuestra tecnología garantiza una <b>inmersión total</b> en tus eventos.
          </p>
          <br>
          <p>
            Gracias a nuestro <b>sistema de estabilización gyrocam</b>, aseguramos una calidad de <b>imagen excepcional</b> manteniendo una <b>baja latencia</b>, incluso en entornos complejos. Disfruta de una <b>transmisión en directo</b> que capta la atención de tu audiencia y transforma cada evento en una <b>experiencia memorable</b>.
          </p>
          <hr>
        </div>

        <!-- Section: Comparaison Stabilisation -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle">Transmisión en Alta Definición estabilizada con gyrocamera</h3>
          <p>
            Los sistemas de transmisión en drones FPV tradicionales presentan limitaciones importantes: calidad de imagen, peso excesivo incompatible con drones pequeños, estabilización que provoca latencia, y restricciones legales.
            <br><br>
            Nuestra solución supera estos desafíos ofreciendo una <b>estabilización giroscópica</b> de punta, también llamada <b>gyrocamera</b>, que permite una <b>alta calidad de imagen</b> y <b>baja latencia</b>. Esta tecnología, ligera y compacta, proporciona tomas <b>dinámicas e inmersivas</b>, perfectas para eventos en directo como deportes extremos, conciertos o festivales.
          </p>
          <br>
          <div class="columns is-multiline" style="margin-top: 2em;">

          <!-- Column for second comparison slider -->
          <div class="column is-6">
            <div class="video-container" id="comparisonContainer2">
              <!-- Bottom video (SD - Transmisión FPV clásica) -->
              <video id="videoBottom2" src="/demo/compareSD2_1.mp4" autoplay muted loop playsinline preload="auto"></video>

              <!-- Top video (HD - Nuestra transmisión) -->
              <div class="video-top" id="videoTop2">
                <video src="/demo/compareHD2_1.mp4" autoplay muted loop playsinline preload="auto"></video>
              </div>

              <!-- The slider for comparison -->
              <div class="slider" id="slider2">
                <div class="slider-icon" id="sliderIcon2">
                  <img src="@/assets/slider.svg" alt="Slider Icon for Video Comparison">
                </div>
              </div>

              <!-- Labels -->
              <div class="video-label video-label-left">Nuestra transmisión FPV</div>
              <div class="video-label video-label-right">Transmisión FPV clásica</div>
            </div>
          </div>
          <!-- Column for first comparison slider -->
          <div class="column is-6">
            <div class="video-container" id="comparisonContainer">
              <!-- Bottom video (SD - Transmisión FPV clásica) -->
              <video id="videoBottom" src="/demo/compareSD_1.mp4" autoplay muted loop playsinline preload="auto"></video>

              <!-- Top video (HD - Nuestra transmisión) -->
              <div class="video-top" id="videoTop">
                <video src="/demo/compareHD_1.mp4" autoplay muted loop playsinline preload="auto"></video>
              </div>

              <!-- The slider for comparison -->
              <div class="slider" id="slider">
                <div class="slider-icon" id="sliderIcon">
                  <img src="@/assets/slider.svg" alt="Slider Icon for Video Comparison">
                </div>
              </div>

              <!-- Labels -->
              <div class="video-label video-label-left">Nuestra transmisión FPV</div>
              <div class="video-label video-label-right">Transmisión FPV clásica</div>
            </div>
          </div>
        </div>

          <p><small>Imágenes capturadas y transmitidas en directo con nuestros drones FPV.</small></p>
          <hr>
        </div>

        <!-- Section: Caractéristiques Techniques et Flexibilité -->
        <div class="column is-12" style="padding-right: 3em; padding-left: 3em; margin-top: 1em;">
          <h3 class="subtitle has-text-left">Características técnicas</h3>
          <div class="columns is-multiline">

            <!-- Resolución 1080p / 1080i a 25 o 50 fps -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/resolution.svg" alt="Resolución 1080p/i" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Transmisión en directo en 1080p o 1080i</b><br>Flexibilidad de transmisión en <b>1080p (progresivo) o 1080i (entrelazado)</b> con una selección de <b>25 a 50 fotogramas por segundo</b>. Esto permite ajustar la calidad de la transmisión según las necesidades del evento.
                  </p>
                </div>
              </div>
            </div>

            <!-- Focal de 75° y gran angular de 100° -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/focallength.svg" alt="Focal" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Elección de la focal</b><br>Una distancia focal de <b>24 mm</b>, gran angular equivalente a <b>15 mm</b>, o incluso anamórfico, para capturar vistas más amplias, primeros planos o dar un aspecto cinematográfico según los requisitos de la realización.
                  </p>
                </div>
              </div>
            </div>

            <!-- Estabilización de 3 ejes -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/stabilized.svg" alt="Estabilización" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Estabilización en 3 ejes</b><br>Imagen sin vibraciones tipo gyrocam, manteniendo el efecto <b>FPV</b>, y una estabilización completa para tomas con efecto <b>"cable cam"</b>. La opción de doble operador permite un control preciso del encuadre, proporcionando una solución perfecta para una realización única.
                  </p>
                </div>
              </div>
            </div>

            <!-- Adaptabilidad a diferentes tipos de drones -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/adaptivity.svg" alt="Adaptabilidad" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Adaptabilidad</b><br>Compatible con una gran variedad de drones, desde drones FPV tradicionales hasta modelos más pequeños para <b>espacios confinados</b>, y drones de <b>alta velocidad</b> para capturar escenas de acción rápida.
                  </p>
                </div>
              </div>
            </div>

            <!-- Baja latencia -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/latency.svg" alt="Latencia" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Baja latencia</b><br>Nuestra tecnología garantiza una <b>baja latencia</b>, ideal para eventos en directo. Tus espectadores experimentarán la <b>acción sin retrasos</b>, perfectamente sincronizada con el evento real.
                  </p>
                </div>
              </div>
            </div>

            <!-- Transmisión de largo alcance -->
            <div class="column is-6">
              <div class="media">
                <figure class="media-left">
                  <img src="@/assets/broadcast.svg" alt="Transmisión" width="40">
                </figure>
                <div class="media-content has-text-justified">
                  <p>
                    <b>Transmisión de largo alcance</b><br>Incluso en entornos complejos, nuestro sistema garantiza una <b>transmisión confiable</b> a una distancia de hasta 7 km. Ya sea en la ciudad o en espacios amplios, el <b>flujo de video permanece estable y de alta calidad</b>.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr>
        </div>
        
        <!-- Sección: Versatilidad -->
        <div class="column is-12 has-text-left" style="padding: 3em;">
          <h3 class="subtitle has-text-weight-bold">Versatilidad de nuestro sistema</h3>
          <p class="has-text-justified">
            Descubre la <b>versatilidad única</b> de nuestro sistema FPV: 
            captura <b>vistas amplias e inmersivas</b>, <b>planos cerrados en el corazón de la acción</b>, todo con una fluidez excepcional en una sola toma. Aquí tienes una demostración de nuestras capacidades.
          </p>
          
          <!-- Comparación: Planos amplios y cerrados -->
          <div class="columns is-multiline is-centered" style="margin-top: 2em;">
            <!-- Video de plano amplio -->
            <div class="column is-6">
              <div class="video-container" style="max-height: 300px;">
                <video src="/demo/wide_shot.mp4" autoplay muted loop playsinline preload="auto"></video>
                <div class="video-label" style="position: absolute; bottom: 10px; right: 20px; background: rgba(0, 0, 0, 0.6); color: white; padding: 5px 10px; border-radius: 5px;">
                  Planos amplios & paisajes
                </div>
              </div>
            </div>
            
            <!-- Video de plano cerrado -->
            <div class="column is-6">
              <div class="video-container" style="max-height: 300px;">
                <video src="/demo/close_shot.mp4" autoplay muted loop playsinline preload="auto"></video>
                <div class="video-label" style="position: absolute; bottom: 10px; right: 20px; background: rgba(0, 0, 0, 0.6); color: white; padding: 5px 10px; border-radius: 5px;">
                  Planos cerrados en el corazón de la acción
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Section: Enregistrement 4K à 100 fps -->
        <div class="column is-12 has-text-left" style="padding-right: 3em; padding-left: 3em;  margin-top: 1em;">
          <h3 class="subtitle">Grabación simultánea para postproducción</h3>
          <p class="has-text-justified" style="margin-bottom: 1em;">
            Nuestro sistema no solo transmite en directo en <b>1080</b>. También permite capturar cada instante en <b>4K hasta 100 fotogramas por segundo</b>, produciendo efectos de cámara lenta fluidos y detallados. Ideal para resúmenes de eventos o para <b>postproducción</b>.
          </p>
          <div class="video-container" id="slowmoContainer">
            <!-- Video for slow motion comparison -->
            <video id="demoSlowmo" src="/demo/demoSlowmo_1.mp4" autoplay muted loop playsinline preload="auto"></video>

            <!-- Labels -->
            <div class="video-label video-label-left">Velocidad 1x en directo</div>
            <div class="video-label video-label-right">Velocidad 0.25x postproducción</div>
          </div>
          <hr>
        </div>

        <!-- Section: Flexibilité et maîtrise technique -->
        <div class="column is-12 has-text-left" style="padding: 1em 3em; margin-bottom: 3em;">
          <h3 class="subtitle has-text-weight-bold">Flexibilidad</h3>
          <p>
            Nuestros pilotos expertos en drones FPV aseguran la <b>seguridad</b> y el <b>cumplimiento legal</b> durante tus eventos, ya sea en entornos urbanos, grandes escenarios de festivales o entornos naturales complejos. Adaptamos nuestras intervenciones a cada situación respetando estrictamente las regulaciones.
          </p>
          <br>
          <p>
            Nuestra tecnología está diseñada para ofrecer flexibilidad total. Ya sea en espacios confinados, pequeños escenarios, o en grandes áreas como montañas, bosques o sobre el agua, nuestros drones garantizan una <b>estabilidad óptima</b>, incluso en presencia de viento, interferencias de radio o en gran altitud. Disfruta de una calidad de imagen impecable, sin importar las condiciones.
          </p>

          <div class="columns is-centered" style="margin-top: 2em;">
            <div class="column is-8" style="text-align: center;">
              <div class="video-container" style="max-height: 300px; max-width: 100%; margin: 0 auto;">
                <video src="/demo/one_shot.mp4" autoplay muted loop playsinline preload="auto"></video>
                <div class="video-label" style="position: absolute; bottom: 10px; right: 20px; background: rgba(0, 0, 0, 0.6); color: white; padding: 5px 10px; border-radius: 5px;">
                  Flexibilidad en entornos complejos
                </div>
              </div>
            </div>
          </div>


          <!-- Icons for environments -->
          <div class="icons-environments" style="margin-top: 2em;">
            <div class="columns is-multiline is-centered is-mobile">
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/mountain.svg" alt="Montaña" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Gran altitud</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/city.svg" alt="Ciudad" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Entorno urbano</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/stages.svg" alt="Concierto" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Conciertos & Festivales</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/interferences.svg" alt="Complejidad de radio" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Entorno con interferencias</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/wind.svg" alt="Viento fuerte" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Viento fuerte</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/forest.svg" alt="Espacios pequeños" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Espacios pequeños y complejos</p>
              </div>
              <div class="column is-4-tablet is-3-desktop has-text-centered">
                <img src="@/assets/water.svg" alt="Sobre el agua" style="width: 50px; height: 50px;">
                <p class="has-text-weight-semibold">Entorno marino</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Call to Action Section -->
        <div class="column is-12 has-text-centered">
          <h4 class="subtitle">
            Planifica una sesión de prueba para experimentar de primera mano y transformar tus eventos:
          </h4>
          <br>
          <button class="button is-black is-outlined is-medium" @click="mailtocontact">
            <span class="icon is-medium">
              <i class="fa fa-envelope"></i>
            </span>
            <span>Contáctanos</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'Live',
  methods: {
    mailtocontact() {
      window.location.href = "mailto:contact@thecompagnie.eu";
    },
    initSlider(containerId, videoTopId, sliderId) {
      const videoTop = document.getElementById(videoTopId);
      const slider = document.getElementById(sliderId);
      const comparisonContainer = document.getElementById(containerId);
      const labelLeft = comparisonContainer.querySelector('.video-label-left');
      const labelRight = comparisonContainer.querySelector('.video-label-right');
      let isDragging = false;

      // Function to update the slider position
      const updateSliderPosition = (x, rect, width) => {
        if (x < 0) x = 0;
        if (x > width) x = width;

        // Update the clip-path of the top video
        videoTop.style.clipPath = `inset(0px ${width - x}px 0px 0px)`;
        slider.style.left = x + 'px';

        // Update the opacity of labels based on slider position
        const threshold = 0.75 * width; // 75% of the width

        if (x < threshold) {
          // Reduce opacity of the left label as slider moves to the left beyond 75%
          const opacityLeft = Math.min(1, (x / threshold));
          labelLeft.style.opacity = opacityLeft;
        } else {
          labelLeft.style.opacity = 1; // Reset to full opacity if under threshold
        }

        if (x > (width - threshold)) {
          // Reduce opacity of the right label as slider moves to the right beyond 75%
          const opacityRight = Math.min(1, ((width - x) / threshold));
          labelRight.style.opacity = opacityRight;
        } else {
          labelRight.style.opacity = 1; // Reset to full opacity if under threshold
        }
      };

      // Set initial slider position (60% of the width)
      const setInitialSlider = () => {
        const rect = comparisonContainer.getBoundingClientRect();
        const width = rect.width;
        updateSliderPosition(0.60 * width, rect, width);
      };

      setInitialSlider();

      // Mouse and touch events for dragging
      const onMouseDown = () => { isDragging = true; };
      const onMouseUp = () => { isDragging = false; };

      const onMouseMove = (e) => {
        if (!isDragging) return;
        const rect = comparisonContainer.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const width = rect.width;
        updateSliderPosition(x, rect, width);
      };

      const onTouchMove = (e) => {
        if (!isDragging) return;
        const rect = comparisonContainer.getBoundingClientRect();
        const touch = e.touches[0];
        const x = touch.clientX - rect.left;
        const width = rect.width;
        updateSliderPosition(x, rect, width);
      };

      // Event listeners
      slider.addEventListener('mousedown', onMouseDown);
      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('mousemove', onMouseMove);

      slider.addEventListener('touchstart', onMouseDown);
      document.addEventListener('touchend', onMouseUp);
      document.addEventListener('touchmove', onTouchMove);
    }
  },
  mounted() {
    // Initialize sliders
    this.initSlider('comparisonContainer', 'videoTop', 'slider');
    this.initSlider('comparisonContainer2', 'videoTop2', 'slider2');
  }
}

</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  max-width: 100%; /* Ensure the container does not exceed page width */
  height: auto;
  aspect-ratio: 16 / 9; /* Maintain 16:9 ratio for videos */
  margin: 0 auto;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1; /* Ensure the video stays behind the top video */
}

/* Upper video styling */
.video-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; /* Make sure the video covers the entire container */
  z-index: 2;
  clip-path: inset(0px 25% 0px 0px); /* Set initial clip to 75% to the right */
}

/* Slider styling */
.slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 75%; /* Set slider to start at 75% */
  width: 5px;
  background-color: #fff;
  cursor: ew-resize;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon in the center of the slider */
.slider-icon {
  position: absolute;
  top: 50%;
  width: 2.5em;
  height: 2.5em;
  transform: translateY(-50%);
  cursor: pointer; /* Makes the SVG clickable */
}

.video-label {
  position: absolute;
  font-size: 0.9em !important;
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  z-index: 4;
  pointer-events: none; /* Pour que le slider reste cliquable */
  user-select: none; /* Désactive la sélection du texte */
}

.slider-icon img {
  pointer-events: none;
  user-select: none; /* Désactive la sélection du logo du slider */
}

@media screen and (max-width: 768px){
.video-label {
  font-size: 0.5em !important;
}
}

.video-label-left {
  bottom: 10px;
  left: 10px;
}

.video-label-right {
  bottom: 10px;
  right: 10px;
}
</style>
